import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { citizen, financial, health, scholarship, employment, legal, utility, travel,property,agriculture,
  environment,business,law,tourism,disaster,transport,judicial,welfare,digital, national,Infrastructure,Energy,
  Environment,Food, Housing, Defence,Census,Water,Consumer,Customs,Election,Skill,Scientific,Forestry,Financial,Railway, ministry, other} from './Sites';

export default function MySearch() {

  const allList=[...citizen, ...financial, ...health, ...scholarship, ...employment, ...legal, ...utility, ...travel,...property, ...agriculture,
    ...environment, ...business, ...law,tourism, ...disaster, ...transport, ...judicial, ...welfare, ...digital, ...national, ...Infrastructure, ...Energy,
    ...Environment, ...Food, ...Housing, ...Defence, ...Census, ...Water, ...Consumer, ...Customs, ...Election, ...Skill, ...Scientific, 
    ...Forestry, ...Financial, ...Railway, ...ministry, ...other]
  
  return (
   
    <Autocomplete
      id="country-select-demo"
      sx={{ width: '100%', minWidth: '180px' }}
      options={allList}
      autoHighlight
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="15"
              srcSet={option.image}
              src= {option.image}
              alt=""
            />
            {option.title}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Government Website"
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            },
          }}
        />
      )}
    />
  );
}


