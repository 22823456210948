import React from 'react';
import './Home.css';
import CardList from './CardList';
import { citizen, financial, health, scholarship, employment, legal, utility, travel,property,agriculture,
  environment,business,law,tourism,disaster,transport,judicial,welfare,digital, national,Infrastructure,Energy,
  Environment,Food, Housing, Defence,Census,Water,Consumer,Customs,Election,Skill,Scientific,Forestry,Financial,Railway,Rural,Education,Mining, ministry, other} from './common/Sites';
import { Box } from '@mui/material';
import MySearch from './common/MySearch';
import { blue } from '@mui/material/colors';


const services = [
  { title: 'Citizen Services', list: citizen },
  { title: 'Financial and Banking Services', list: financial },
  { title: 'Health and Welfare Services', list: health },
  { title: 'Educational Services', list: scholarship },
  { title: 'Employment Services', list: employment },
  { title: 'Legal and Grievance Services', list: legal },
  { title: 'Utility Services', list: utility },
  { title: 'Transport and Travel Services', list: travel },
  { title: 'Land and Property Services', list: property },
  { title: 'Agricultural and Rural Services', list: agriculture },
  { title: 'Environmental Services', list: environment },
  { title: 'Business and Trade Services', list: business },
  { title: 'Law Enforcement and Safety', list: law },
  { title: 'Tourism and Cultural Services', list: tourism },
  { title: 'Disaster Management', list: disaster },
  { title: 'Transportation and Vehicle Services', list: transport },
  { title: 'Judicial Services', list: judicial},
  { title: 'Social Welfare Services', list: welfare},
  { title: 'Digital and Technology Services', list: digital},
  { title: 'Disaster Management', list: national },
  { title: 'Infrastructure Services', list: Infrastructure},
  { title: 'Energy and Power Services', list: Energy},
  { title: 'Environment and Climate Change Services', list:  Environment},
  { title: 'Food and Public Distribution Services', list:  Food },
  { title: 'Housing and Urban Development Services', list:   Housing },
  { title: 'Defence and Veterans Services', list:    Defence },
  { title: 'Census and Statistics Services', list:    Census  },
  { title: 'Water Resource Management', list:    Water  },
  { title: 'Consumer Protection Services', list:    Consumer  },
  { title: 'Customs and Excise Services', list:    Customs  },
  { title: 'Election Services', list:    Election  },
  { title: 'Skill Development and Employment', list:    Skill  },
  { title: 'Scientific Research and Innovation', list:    Scientific  },
  { title: 'Forestry and Wildlife Services', list:    Forestry  },
  { title: 'Financial Inclusion Services', list:    Financial},
  { title: 'Railway and Transport Services', list:    Railway},
  { title: 'Rural Development Services', list:    Rural},
  { title: 'Education Services', list:    Education},
  { title: 'Mining and Geology Services', list:    Mining},
  { title: 'Miscellaneous Services', list: other },
  { title: 'Ministries and Key Government Departments', list: ministry },
];

const Home = () => {
  return (
    <Box
    marginTop={8}
    display="flex"
    flexDirection="column"
    alignItems="center">
    <Box sx={{ 
    width: '90%', 
    maxWidth: '300px', // or another appropriate max width
    marginBottom: '10px'
   }}>
        <MySearch />
      </Box>
    <Box 
    display="flex"
    flexDirection="row"
    justifyContent="left"
    alignItems="start"
    flexWrap="wrap"
    margin={4}
    gap={8}
    >
      {services.map((service, index) => {
        // Debugging
        console.log(`Service ${index}:`, service.list);
        return (
          <CardList
            key={index}
            serviceName={service.title}
            listName={service.list}
          />
        );
      })}
    </Box>
    </Box>
  );
}

export default Home;
