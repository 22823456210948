import React from 'react';
import './CardList.css';
import { SiteCard_new } from './common/SiteCard_new';
import { Box } from '@mui/material';

const CardList = ({ serviceName, listName }) => {
  // Verify that listName is an array
  if (!Array.isArray(listName)) {
    console.error(`Expected listName to be an array, but received: ${typeof listName}`);
    return null; // Or handle the error appropriately
  }

  return (
    <div>
      <Box className="outlined-box" >
      <h2>{serviceName}</h2>
        <div className="card-container">
          {listName.map((item) => (
            <SiteCard_new
              key={item.id}
              image={item.image}
              name={item.title}
              description={item.description}
              link={item.link}
            >
              {item.id}
            </SiteCard_new>
          ))}
        </div>
      </Box>
    </div>
  );
}

export default CardList;
