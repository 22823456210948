
import { Box } from '@mui/material';
import './App.css';
import Home from './Home';
import MyAppBar from './common/MyAppBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Box>
      <BrowserRouter>
        <MyAppBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />}/>
        </Routes>
      </BrowserRouter>
  </Box>
  );
}

export default App;
