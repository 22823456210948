// src/data.js
export const citizen = [
    { id: 1, title: "Aadhaar Services", description: "UIDAI Portal – Apply for an Aadhaar card, update details, download e-Aadhaar, and check Aadhaar status.",image:"/images/aadhar.svg" ,link:"https://uidai.gov.in/"},
    { id: 2, title: "Passport Services", description: "NPassport Seva – Apply for a new passport, renew an existing one, check application status, and schedule appointments." ,image:"/images/passport.jpeg",link:"" },
    { id: 3, title: "PAN Card Services", description: "NSDL e-Gov and UTIITSL – Apply for a new PAN card, request corrections, track application status, and reprint PAN cards." ,image:"/images/uti.png" ,link:""},
    { id: 4, title: "Voter ID Services",description: "National Voter Services Portal (NVSP) – Register as a new voter, update voter details, check voter ID status, and download an e-Voter ID",image:"/images/votarid.svg",link:"https://voters.eci.gov.in/" }, 
    { id: 5, title: "Driving License Services", description: "Parivahan Sewa – Apply for a driving license, renew existing licenses, check driving license status, and pay road taxes." ,image:"/images/dl.png" ,link:"https://parivahan.gov.in/"},
    { id: 6, title: "Income Tax e-Filing", description: "Income Tax e-Filing Portal – File income tax returns, check refund status, and download Form 16." ,image:"/images/itr.svg" ,link:"https://www.incometax.gov.in/"},
    { id: 7, title: "Birth and Death Certificates", description: "Apply for birth and death certificates, correction services, and certificate verification (varies by state)." ,image:"images/birthanddeath.jpeg",link:"" },
  ];

  export const financial = [
    { id: 1, title: "GST Services", description: "GST Portal – Register for GST, file returns, pay GST, and claim refunds.",image:"/images/gst.jpeg" ,link:"https://www.gst.gov.in/"},
    { id: 2, title: "EPF Services", description: "EPFO Portal – Check Employee Provident Fund (EPF) balance, download UAN card, update KYC details, and claim EPF." ,image:"/images/epfo.png",link:"" },
    { id: 3, title: "Pension Services:", description: "Jeevan Pramaan – Generate a digital life certificate for pensioners and update pensioner details." ,image:"/images/jp_logo64.png",link:"https://jeevanpramaan.gov.in/" },
    { id: 4, title: "National Pension System (NPS):", description: "NPS Trust – Open an NPS account, contribute to the NPS account, and track NPS investments." ,image:"/images/nps.png" ,link:""}, 
  ];

  export const health = [
    { id: 1, title: " Ayushman Bharat (PM-JAY)", description: "Ayushman Bharat Portal – Check eligibility for health benefits, locate hospitals, and register for the scheme.",image:"/images/pmjay.png",link:"https://pmjay.gov.in/" },
    { id: 2, title: "National Digital Health Mission (NDHM) ", description: "National Digital Health Mission (NDHM) – Create a unique health ID and manage digital health records." ,image:"/images/health.png",link:"https://abdm.gov.in/" },
    { id: 3, title: "COVID-19 Services", description: "CoWIN Portal – Register for COVID-19 vaccination, download the vaccination certificate, and find nearby vaccination centers." ,image:"/images/covid.png" ,link:"https://www.cowin.gov.in/"},
    { id: 3, title: "National Health Mission (NHM)", description: "NHM Portal – Access healthcare services, maternal and child health programs, and disease control initiatives." ,image:"/images/nhm.jpg" ,link:"https://nhm.gov.in/"},
  ];

  export const scholarship = [
    { id: 1, title: "Scholarships", description: "National Scholarship Portal (NSP) – Apply for various scholarships, check application status, and renew scholarships.",image:"/images/scholarship.svg",link:"https://scholarships.gov.in/" },
    { id: 2, title: "Online Learning: ", description: "SWAYAM – Access free online courses across various disciplines, including engineering, humanities, and social sciences." ,image:"/images/swayam_logo.png",link:"https://swayam.gov.in/" },
    { id: 3, title: "CBSE and State Boards", description: "CBSE – Download board exam results, admit cards, and study materials" ,image:"/images/cbsc.png",link:"https://www.cbse.gov.in/" },
  ];


  export const employment = [
    { id: 1, title: "National Career Service (NCS)", description: "NCS Portal – Search for jobs, register for employment, skill development, and career counseling.",image:"/images/ncs_new.jpeg" ,link:"https://www.ncs.gov.in/"},
    { id: 2, title: "Employment Exchange Registration ", description: "State Employment Portals – Register with local employment exchanges and access job listings" ,image:"/images/eer.png" ,link:"state.png"},
  ];

  export const legal = [
    { id: 1, title: "legal Services", description: "e-Courts – Track case status, access court orders/judgments, and e-filing of cases",image:"/images/legal.jpeg" ,link:"https://ecourts.gov.in/ecourts_home/"},
    { id: 2, title: "Public Grievance Redressal ", description: "Centralized Public Grievance Redress and Monitoring System (CPGRAMS) – Submit grievances to central or state government departments and track the status." ,image:"/images/PGPORTAL.png" ,link:"https://pgportal.gov.in/"},
    { id: 3, title: "Right to Information (RTI)", description: "TRTI Online – File RTI applications, check status, and receive replies from public authorities." ,image:"/images/rti.png" ,link:"https://rtionline.gov.in/"},


  ];

  export const utility = [
    { id: 1, title: "Bill Payments", description: "Bharat Bill Payment System (BBPS) – Pay utility bills, including electricity, water, gas, and phone bills.",image:"/images/icon_logo.svg" ,link:"https://www.bharatbillpay.com/"},
    { id: 2, title: "Gas Connection Services", description: "Indane, HP Gas, Bharat Gas – Book LPG cylinder refills, check booking status, and apply for new connections" ,image:"/images/gas.jpeg" ,link:""},
    { id: 3, title: "Electricity Services", description: "State-Specific Portals – Pay electricity bills, apply for new connections, and check bill status." ,image:"/images/electricity.jpeg",link:"" },
  ];

  export const travel = [
    { id: 1, title: "Indian Railways", description: "IRCTC – Book train tickets, check train status, and access tourism packages.",image:"/images/irctc.png" ,link:"https://www.irctc.co.in/"},
    { id: 2, title: "Air Travel", description: "AirSewa – Grievance redressal and information about flights, airports, and aviation services" ,image:"/images/airseva.jpeg",link:"https://www.airsewa.gov.in/" },
    { id: 3, title: "FASTag Services", description: "NETC FASTag – Recharge FASTag, check transaction history, and view toll plazas" ,image:"/images/fasttag.png",link:"" },
    

  ];


  export const property = [
    { id: 1, title: "Land Records", description: "Bhoomi (Karnataka), Bhulekh (Uttar Pradesh), Maa Bhoomi (Telangana), and other state-specific portals – Access land records, property ownership details, mutation status, and map records.",image:"/images/bhulekh.png" ,link:"https://upbhulekh.gov.in/"},
    { id: 2, title: "Property Registration", description: "e-District Portals – Register property transactions, view encumbrance certificates, and pay stamp duty." ,image:"/images/edistup.png",link:"hhttps://edistrict.up.gov.in/edistrictup/" },
    
  ];

  export const agriculture = [
    { id: 1, title: "Pradhan Mantri Kisan Samman Nidhi (PM-KISAN)", description: "PM-KISAN Portal – Register for the scheme, check payment status, and update beneficiary details.",image:"/images/pmKisan.jpeg" ,link:"https://pmkisan.gov.in/"},
    { id: 2, title: "Soil Health Card Portal", description: "Soil Health Card Portal – Apply for soil health cards, check soil testing status, and receive soil recommendations for crops." ,image:"/images/soil_health.png",link:"https://soilhealth.dac.gov.in/home" },
    { id: 3, title: "Farmer Registration and Support", description: "Farmer Portal – Access agriculture-related services, including crop insurance, weather updates, seed information, and market prices." ,image:"/images/mKisan.png",link:"https://mkisan.gov.in/Home/FarmerRegistration" },
    { id: 4, title: "e-NAM (National Agriculture Market)", description: "e-NAM Portal – Sell agricultural produce online, access market prices, and get information on local mandis (agricultural markets)." ,image:"/images/enam-logo.png",link:"https://enam.gov.in/web/" },
    
  ];
  export const environment = [
    { id: 1, title: "Forest Clearances", description: "Parivesh Portal – Apply for forest clearances, monitor environment and wildlife conservation applications, and track forest land diversion cases.",image:"/images/pariveshlogo.png" ,link:"https://parivesh.nic.in/"},
    { id: 2, title: "Pollution Monitoring", description: "Central Pollution Control Board (CPCB) – Check real-time air and water quality, file pollution complaints, and view environmental regulations." ,image:"/images/cpcb.jpg",link:"https://cpcb.nic.in/" },
    
  ];
  export const business = [
    { id: 1, title: "Company Registration", description: "Ministry of Corporate Affairs (MCA) – Register new companies, file annual returns, view compliance documents, and check company status.",image:"/images/mca.png" ,link:"https://www.mca.gov.in/"},
    { id: 2, title: "Import Export Code (IEC)", description: "DGFT Portal – Apply for an IEC, modify the code, and track import-export transactions." ,image:"/images/iec.png",link:"https://www.dgft.gov.in" },
    { id: 3, title: "Startup India", description: "Startup India – Register a startup, apply for tax benefits, access funding schemes, and explore mentorship and training programs." ,image:"/images/statupindia.png",link:"https://www.startupindia.gov.in/" },
    
  
  ];

  export const law = [
    { id: 1, title: "Cyber Crime Reporting", description: "National Cyber Crime Reporting Portal – Report cyber crimes like hacking, identity theft, online fraud, and harassment.",image:"/images/nccrp.png" ,link:"https://cybercrime.gov.in/"},
    { id: 2, title: "e-FIR Filing", description: "State-specific police websites such as Delhi Police e-FIR and Mumbai Police – File First Information Reports (FIRs) online for non-emergency crimes." ,image:"/images/efir.png",link:"https:https://mumbaipolice.gov.in/" },
    { id: 3, title: "Online Traffic Challans", description: "Indian Visa Online – Apply for Indian tourist, business, or medical visas, track visa status, and download e-visas." ,image:"/images/echallan.jpeg",link:"https:/https://echallan.parivahan.gov.in/" },
    
  
  ];
  export const tourism = [
    { id: 1, title: "Incredible India", description: "Incredible India Portal – Explore travel destinations, apply for visas, check tourist services, and access event information.",image:"/images/nccrp.png" ,link:"https://www.incredibleindia.org/"},
    { id: 2, title: "ASI Monuments Ticketing", description: "Archaeological Survey of India (ASI) – Book tickets to historical monuments like the Taj Mahal, Red Fort, and Qutub Minar." ,image:"/images/arc.jpeg",link:"https:https:/https://asi.nic.in/" },
    { id: 3, title: "e-Visa Application", description: "Indian Visa Online – Apply for Indian tourist, business, or medical visas, track visa status, and download e-visas." ,image:"images/evisa.jpeg",link:"https://indianvisaonline.gov.in/" },
  ];

  export const social = [
    { id: 1, title: "Pradhan Mantri Jan Dhan Yojana (PMJDY)", description: "PMJDY Portal – Open bank accounts under the Jan Dhan scheme, track account balances, and access financial inclusion services.",image:"/images/pmj.png" ,link:"https:/https://pmjdy.gov.in/"},
    { id: 2, title: "ational Social Assistance Program (NSAP)", description: "NSAP Portal – Apply for pension schemes, widow and disability pensions, and check pension status." ,image:"/images/nsap.svg",link:"https:https:/https://nsap.nic.in/" },
    { id: 3, title: "Direct Benefit Transfer (DBT)", description: "DBT Bharat – Track subsidies and benefits directly credited to your bank account, including LPG subsidies and welfare payments. " ,image:"/images/sashok.png",link:"https:/https://echallan.parivahan.gov.in/" },
  ];
  export const disaster = [
    { id: 1, title: "Disaster Alerts", description: "National Disaster Management Authority (NDMA) – Receive disaster alerts, weather warnings, and safety guidelines for earthquakes, floods, and other natural disasters.",image:"/images/dis.png" ,link:"https://https://ndma.gov.in/"},
    { id: 2, title: "SAFAR (System of Air Quality and Weather Forecasting and Research)", description: "SAFAR India – Access real-time air quality data, pollution forecasts, and weather updates for Indian cities." ,image:"/images/efir.png",link:"https:https://mumbaipolice.gov.in/" },
   
  ];
  export const transport = [
    { id: 1, title: "VAHAN", description: "VAHAN Portal – Register vehicles, pay road taxes, transfer ownership, and renew vehicle registration.",image:"/images/vahan.jpeg" ,link:"https://parivahan.gov.in/"},
    { id: 2, title: "Sarathi", description: "Sarathi Portal – Apply for new driving licenses, renew licenses, book driving tests, and track application status." ,image:"/images/parivahan.png",link:"https://parivahan.gov.in/" },
    { id: 3, title: "National Permit for Commercial Vehicles", description: "National Permit System – Apply for national permits for commercial vehicles, renew permits, and access permit status. " ,image:"/images/permit.png",link:"https://vahan.parivahan.gov.in/" },
  ];
  export const judicial = [
    { id: 1, title: "e-Courts Portal", description: "e-Courts India – Search case status, check hearing dates, view court orders/judgments, and file cases online.",image:"/images/ecort.png" ,link:"https://ecourts.gov.in/"},
    { id: 2, title: "Supreme Court e-Committee ", description: "Supreme Court e-Committee – Access Supreme Court case status, judgements, and court notifications." ,image:"/images/ecourtsindia.jpeg",link:"https://ecommitteesci.gov.in/" }
  ];
  export const welfare = [
    { id: 1, title: "Pradhan Mantri Jan Dhan Yojana (PMJDY):", description: "PMJDY Portal – Open bank accounts under the Jan Dhan scheme, track account balances, and access financial inclusion services.",image:"/images/pmjdy.png" ,link:"https://pmjdy.gov.in/"},
    { id: 2, title: "National Social Assistance Program (NSAP)", description: "NSAP Portal – Apply for pension schemes, widow and disability pensions, and check pension status." ,image:"images/logo-nsap.svg",link:"https://nsap.nic.in/" },
    { id: 3, title: "Direct Benefit Transfer (DBT)", description: "DBT Bharat – Track subsidies and benefits directly credited to your bank account, including LPG subsidies and welfare payments." ,image:"/images/dbt_logo (1).png",link:"https://dbtbharat.gov.in/" },
  ];
  export const digital  = [
    { id: 1, title: "UMANG App", description: "UMANG Portal – Access over 1200 central and state government services, including EPF, PAN, utility bill payments, and scholarship applications.",image:"/images/logo.svg" ,link:"https://web.umang.gov.in/"},
    { id: 2, title: "BharatNet", description: "BharatNet Portal – Connect rural areas with high-speed internet under the BharatNet initiative." ,image:"/images/bharatnet.jpeg",link:"https://bbnl.nic.in/" },
    { id: 3, title: "DigiSevak", description: "DigiSevak Portal – Volunteer for digital initiatives like Digital India and contribute to the country’s e-governance mission." ,image:"/images/digi.png",link:"http://digisevak.gov.in/" },
  ];
  export const national = [
    { id: 1, title: "National Portal of India", description: "This is the official web portal of the Government of India, providing information and services related to different ministries, departments, and state governments",image:"/images/indiangov.png" ,link:"https://www.india.gov.in/"},
    { id: 2, title: "Prime Minister Of India", description: "The official website of the Prime Minister's Office." ,image:"/images/pmindia.png",link:"https://www.pmindia.gov.in/" },
    { id: 3, title: "The President of India", description: "The official site of the President of India." ,image:"/images/precident.png",link:"https://www.presidentofindia.gov.in/" },
    { id: 4, title: "MyGov", description: "A platform for citizens to engage with the government and contribute to nation-building." ,image:"/images/mygov.svg",link:"https://www.mygov.in/" },

  ];
  export const  Infrastructure = [
    { id: 1, title: "Bharatmala Project", description: "Bharatmala Pariyojana – Track progress of national highway development projects aimed at improving road connectivity across India.",image:"/images/bharatmala.png" ,link:"/images/bharatmala.png"},
    { id: 2, title: "Smart Cities Mission", description: "Smart Cities Mission Portal – Information on urban development projects under the Smart Cities Mission, including city-specific projects and citizen participation." ,image:"/images/smart city.png",link:"https://smartcities.gov.in/" },
    { id: 3, title: "AMRUT (Atal Mission for Rejuvenation and Urban Transformation)", description: "AMRUT Portal – Access details on urban infrastructure projects like water supply, sewage treatment, and urban transport." ,image:"/images/AMRUT.png",link:"AMRUT.Gov.in" },
  ];
  export const  Energy = [
    { id: 1, title: "URJA (Urban Jyoti Abhiyaan)", description: "URJA Dashboard – Track real-time performance of power distribution companies (DISCOMs) in urban areas and check electricity supply status.",image:"/images/energy.png" ,link:"https://egramswaraj.gov.in/"},
    { id: 2, title: "Saubhagya – Pradhan Mantri Sahaj Bijli Har Ghar Yojana", description: "Saubhagya Portal – Check household electrification status and track progress of rural and urban electrification." ,image:"/images/saubhagya.jpeg",link:"https://powermin.gov.in/" },
    { id: 3, title: "Ujjwala Yojana", description: "PMUY (Pradhan Mantri Ujjwala Yojana) – Apply for free LPG gas connections for eligible households, check application status, and subsidy details." ,image:"/images/pmuy.png",link:"https://pmuy.gov.in/" },
  ];
  export const Environment  = [
    { id: 1, title: "Green India Mission", description: "Green India Portal – Monitor afforestation and forest conservation programs across India.",image:"/images/ecort.png" ,link:"https://ecourts.gov.in/"},
    { id: 2, title: "Climate Change Mitigation", description: "National Action Plan on Climate Change (NAPCC) – Track India's national efforts to combat climate change through various mission-based approaches." ,image:"/images/ecourtsindia.jpeg",link:"https://ecommitteesci.gov.in/" }
  ];
  export const   Food = [
    { id: 1, title: "Public Distribution System (PDS))", description: "State-specific PDS Portals – Apply for a ration card, check food grain entitlement, and download digital ration cards..",image:"/images/nfs.png" ,link:"https://nfsa.gov.in/"},
    { id: 2, title: "Integrated Management of Public Distribution System (IM-PDS)", description: "IM-PDS Portal – Track food subsidy benefits, access PDS portability, and check ration card status across states." ,image:"/images/df.png",link:"https://impds.nic.in/" },
    { id: 3, title: "Pradhan Mantri Garib Kalyan Anna Yojana (PMGKAY)", description: "PMGKAY Portal – Information on free food grains distribution under the PMGKAY scheme, launched during COVID-19." ,image:"/images/pmg.jpg",link:"https://dfpd.gov.in/" },
  ];
  export const  Housing  = [
    { id: 1, title: "Pradhan Mantri Awas Yojana (PMAY)", description: "PMAY Portal – Apply for affordable housing under PMAY, check eligibility, track application status, and access housing finance benefits.",image:"/images/pmay-logo.jpg" ,link:"https://pmaymis.gov.in/"},
    { id: 2, title: "National Urban Livelihoods Mission (NULM)", description: "NULM Portal – Access livelihood opportunities for urban poor, skill training, and shelter services for the homeless." ,image:"/images/deen.png",link:"https://nulm.gov.in/" }
  ];
  export const  Defence   = [
    { id: 1, title: "Indian Army Veterans Portal", description: "ECHS Portal – Access healthcare services for veterans under Ex-Servicemen Contributory Health Scheme (ECHS), check hospital networks, and apply for medical cards.",image:"/images/veterans.jpeg" ,link:"https://pmaymis.gov.in/"},
    { id: 2, title: "Seva Nidhi", description: "Seva Nidhi Portal – Online pension and welfare services for Indian armed forces veterans and their families." ,image:"/images/sevanidhi.svg",link:"https://pmsvanidhi.mohua.gov.in/" }
  ];
  export const  Census   = [
    { id: 1, title: "National Family Health Survey (NFHS)", description: "NFHS Portal – Access health, nutrition, and demographic statistics from the National Family Health Survey.",image:"/images/nfhslogo.jpeg" ,link:"http://rchiips.org/"},
    { id: 2, title: "National Sample Survey Office (NSSO)", description: "NSSO Portal – Access socio-economic data from national surveys on employment, health, and education." ,image:"/images/mospi.png",link:"https://mospi.gov.in/" }
  ];
  export const Water = [
    { id: 1, title: "Jal Jeevan Mission (JJM)", description: "JJM Dashboard – Monitor rural household water supply and track progress under the mission to provide safe drinking water to rural areas.",image:"/images/jjm.png" ,link:"https://ejalshakti.gov.in/"},
    { id: 2, title: "Pradhan Mantri Krishi Sinchai Yojana (PMKSY)", description: "PMKSY Portal – Information on irrigation and water conservation schemes, watershed management, and micro-irrigation programs." ,image:"/images/pmk.png",link:"https://pmksy.gov.in/" },
    
  ];
  export const   Consumer   = [
    { id: 1, title: "National Consumer Helpline (NCH)", description: "NCH Portal – Register consumer complaints, track complaint status, and access consumer rights information.",image:"/images/jago1.png" ,link:"https://consumerhelpline.gov.in/"},
    { id: 2, title: "e-Daakhil", description: "e-Daakhil Portal – File consumer complaints in consumer courts and check case status online." ,image:"/images/edakhil.png",link:"https://edaakhil.nic.in/" }
  ];
  export const   Customs   = [
    { id: 1, title: "Indian Customs Electronic Gateway (ICEGATE)", description: "ICEGATE Portal – File customs duty returns, track import-export shipments, and apply for refunds.",image:"/images/icegate2.0.png" ,link:"https://www.icegate.gov.in/"},
    { id: 2, title: "Central Board of Indirect Taxes and Customs (CBIC)", description: "CBIC Portal – Access services related to GST, customs duties, and central excise, including filing, payments, and refunds." ,image:"/images/CBIC_logo.png",link:"https://www.cbic.gov.in/" }
  ];
  export const   Election   = [
    { id: 1, title: "Election Commission of India (ECI) Online Services", description: "ECI Portal – Register to vote, find polling stations, track voter ID status, and view election results.",image:"/images/eci-logo.svg" ,link:"https://www.eci.gov.in/"},
    { id: 2, title: "Know Your Candidate", description: "Know Your Candidate Portal – View the details of contesting candidates, including assets, criminal records, and educational qualifications." ,image:"/images/eci-logo (1).svg",link:"https://www.eci.gov.in/"  }
  ];
  export const   Skill   = [
    { id: 1, title: "Skill India Mission", description: "Skill India Portal – Register for skill development courses, explore job opportunities, and access information on vocational training centers..",image:"/images/kaushal.png" ,link:"https://www.pmkvyofficial.org/"},
    { id: 2, title: "PM Kaushal Vikas Yojana (PMKVYl", description: "PMKVY Portal – Apply for skill development training programs, check certification status, and explore career opportunities." ,image:"/images/skills.jpeg",link:"https://edaakhil.nic.in/" }
  ];
  export const   Scientific   = [
    { id: 1, title: "Indian Council of Medical Research (ICMR)", description: "ICMR Portal – Access research publications, clinical trial data, and guidelines on medical research and healthcare innovation.",image:"/images/icmr.svg" ,link:"https://www.icmr.gov.in/"},
    { id: 2, title: "Council of Scientific and Industrial Research (CSIR)", description: "CSIR Portal – Explore scientific research projects, innovation patents, and technology development initiatives in India." ,image:"/images/csir-logo.png",link:"https://www.csir.res.in/" }
  ];
  export const   Forestry  = [
    { id: 1, title: "Wildlife Crime Control Bureau (WCCB)", description: "WCCB Portal – Report wildlife crime, access wildlife protection laws, and track endangered species.",image:"/images/logo.png" ,link:"https://wccb.gov.in/"},
    { id: 2, title: "National Biodiversity Authority (NBA)", description: "NBA Portal – Access information on biodiversity conservation, bio-resources, and biodiversity management committees." ,image:"/images/nba1.png",link:"http://nbaindia.org/" }
  ];  
  export const    Financial  = [
    { id: 1, title: "Mudra Loan", description: "PM MUDRA Yojana – Apply for loans for small and micro-enterprises, check loan status, and access beneficiary schemes.",image:"/images/logo (2).png" ,link:"https://www.mudra.org.in/"},
    { id: 2, title: "Stand Up India", description: "Stand Up India Portal – Access bank loans for Scheduled Castes, Scheduled Tribes, and women entrepreneurs." ,image:"/images/standup.png",link:"https://www.standupmitra.in/" }
  ]; 
   export const   Railway = [
    { id: 1, title: "Railway Recruitment Board (RRB)", description: "RRB Portal – Apply for Indian Railways jobs, track application status, and download admit cards.",image:"/images/logo22.png" ,link:"https://indianrailways.gov.in/"},
    { id: 2, title: "Freight Business Development Portal", description: "Indian Railways Freight Portal – Book freight transport, check freight rates, and monitor railway cargo." ,image:"/images/railways.gif",link:"https://indianrailways.gov.in/" }
  ]; 
  export const  Rural  = [
    { id: 1, title: "Mahatma Gandhi National Rural Employment Guarantee Act (MGNREGA)", description: "MGNREGA Portal – Apply for rural employment under MGNREGA, check job card status, and view work progress.",image:"/images/logo_nrega.jpg" ,link:"https://nrega.nic.in/"},
    { id: 2, title: "Deen Dayal Upadhyaya Grameen Kaushalya Yojana (DDU-GKY)", description: "DDU-GKY Portal – Access skill development and employment programs aimed at rural youth." ,image:"/images/ddu.jpeg",link:"https://ddugky.gov.in/" },
    { id: 3, title: "Pradhan Mantri Gram Sadak Yojana (PMGSY))", description: "PMGSY Portal – Track rural road development projects, check road construction progress, and explore rural connectivity plans." ,image:"/images/pmgsy.jpeg",link:"https://pmgsy.nic.in/" },
  ];
  export const Education  = [
    { id: 1, title: "National Digital Library of India (NDLI)", description: "Explore a vast digital repository of academic resources, including books, research papers, and theses.",image:"/images/ndli.png" ,link:"https://ndl.iitkgp.ac.in/"},
    { id: 2, title: "Shala Darpan", description: "Shala Darpan Portal – Access school management and student progress information for Kendriya Vidyalayas and other central schools." ,image:"/images/shaladarpan.png",link:"https://rajshaladarpan.nic.in/" },
    ];
    export const Mining  = [
      { id: 1, title: "Indian Bureau of Mines (IBM)", description: "IBM Portal – Apply for mining leases, track mineral exploration activities, and access mine safety regulations.",image:"/images/ibm.png" ,link:"https://ibm.gov.in/"},
      ];
  export const ministry = [
    { id: 1, title: "Ministry of Home Affairs (MHA)", description: "Responsible for internal security, law and order, border management, and disaster management.",image:"/images/moha.png" ,link:"https://www.mha.gov.in/"},
    { id: 2, title: "Ministry of Finance ", description: "Manages government financial policies, taxation, financial regulations, and the country's budget" ,image:"/images/mofin.png",link:"https://www.finmin.nic.in" },
    { id: 3, title: "Ministry of External Affairs (MEA)", description: "Handles India's foreign relations, diplomacy, and international engagements." ,image:"/images/moea.png",link:"https://www.mea.gov.in/" },
    { id: 4, title: " Ministry of Defence", description: "Responsible for safeguarding national security and overseeing the armed forces." ,image:"/images/mod.png",link:" https://www.mod.gov.in/" },
    { id: 5, title: "Ministry of Education", description: "Manages education policy, schools, higher education, and national education reforms.",image:"/images/moe.png" ,link:"https://www.education.gov.in/"},
    { id: 6, title: "Ministry of Health & Family Welfare ", description: "Oversees public health, medical education, and family welfare programs." ,image:"/images/mohfw.png",link:"https://www.mohfw.gov.in/" },
    { id: 7, title: "Ministry of Railways", description: "Manages Indian Railways, including operations, infrastructure, and services." ,image:"/images/indianrailway.png",link:" https://indianrailways.gov.in/" },
    { id: 8, title: "Ministry of Road Transport and Highways", description: "ocuses on the development and maintenance of national highways and road transport." ,image:"/images/morth.png",link:"https://morth.nic.in/" },
    { id: 9, title: "Ministry of Communications", description: "Governs postal services, telecommunications, and internet connectivity in India.",image:"/images/motell.png" ,link:"https://dot.gov.in/"},
    { id: 10, title: "Ministry of Agriculture and Farmers' Welfare", description: "Responsible for agricultural policy, farmer welfare, and food security" ,image:"/images/moafw.png",link:"https://agricoop.nic.in/" },
    { id: 11, title: "Ministry of Commerce and Industry", description: "Manages trade, commerce, industrial development, and export policies." ,image:"/images/moci.png",link:"https://commerce.gov.in/" },
    { id: 12, title: "Ministry of Environment, Forest, and Climate Change", description: "Focuses on environmental conservation, forest management, and climate action" ,image:"/images/moefcc.png",link:" https://moef.gov.in/" },
    { id: 13, title: "Ministry of Housing and Urban Affairs", description: "aManages urban development, housing projects, and policies for sustainable cities",image:"/images/mohua.png" ,link:"https://mohua.gov.in/"},
    { id: 14, title: "Ministry of Labour and Employment", description: "Oversees labor laws, employment policies, and worker welfare programs" ,image:"/images/mole.png",link:"https://labour.gov.in/" },
    { id: 15, title: "Ministry of Power", description: "esponsible for electricity generation, distribution, and national energy policies." ,image:"/images/mopower.png",link:"https://powermin.gov.in/" },
   
  ];


  export const other = [
    { id: 1, title: "Digital Locker (DigiLocker)", description: "DigiLocker – Store digital documents, share documents electronically, and access various government-issued certificates.",image:"/images/digilocker_logo_new.png",link:"https://www.digilocker.gov.in/" },
    { id: 2, title: "e-Signature Services", description: "e-Sign Service – Generate electronic signatures for documents." ,image:"/images/esign.svg" ,link:"https://www.csc.gov.in/"},
    { id: 3, title: "GeM (Government e-Marketplace)", description: "GeM – Government procurement portal for businesses to sell products and services to the government." ,image:"/images/gem.svg" ,link:"https://gem.gov.in/"},
    

  ];


  export const serviceData = {
    citizen,
    financial,
    health,
    scholarship,
    employment,
    legal,
    utility,
    travel,
    other
  };

  export const services = [
    { id: 1, title: "citizen", description: "Citizen Services",image:"/images/aadhar.svg" },
    { id: 2, title: "financial", description: "Financial and Banking Services" ,image:"/images/aadhar.svg" },
    { id: 3, title: "health", description: "Health and Welfare Services" ,image:"/images/aadhar.svg" },
    { id: 4, title: "scholarship", description: "Educational Services" ,image:"/images/aadhar.svg" }, 
    { id: 5, title: "employment", description: "Employment Services" ,image:"/images/aadhar.svg" },
    { id: 6, title: "legal", description: "Legal and Grievance Services" ,image:"/images/aadhar.svg" },
    { id: 7, title: "utility", description: "Utility Services" ,image:"/images/aadhar.svg" },
    { id: 8, title: "travel", description: "Transport and Travel Services" ,image:"/images/aadhar.svg" },
    { id: 9, title: "other", description: "Miscellaneous Services" ,image:"/images/aadhar.svg" },

  ];