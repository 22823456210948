import React from 'react';
import { Card, CardContent, Link, Typography, Chip, Box } from '@mui/material';

export const SiteCard_new = ({ name, image, description, link }) => {
  return (
    <Card
      variant="outlined"
      orientation="vertical"
      sx={{
        width: 110,
        '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', // Center content horizontally
      }}
    >
      <Box
        component="img"
        src={image}
        loading="lazy"
        alt={name}
        height="40px"
        width="100px"
        sx={{ marginTop: '8px', display: 'block', textAlign: 'center' }} // Ensures image is centered
      />

      <CardContent sx={{ textAlign: 'center', width: '90%' }}> {/* Center align text */}
        <Typography variant="body2" id="card-description">
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
};
